@import "../../breakpoints.scss";

header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 25px;
  padding-top: 15px;
  box-sizing: border-box;
  justify-content: space-between;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 100%);

  @include for-phone-only {
     padding-bottom: 35px;
  }

  a.logo{
    position: relative;
    top: 8px;
    color: rgba(255,255,255,0);
    font-size: 30px;
    background-image: url(./ekoengineering_logo.svg);
    background-size: 100%;
    background-repeat: no-repeat;

    span {
      padding-left: 5px;
      font-size: 16px;
    }

    @include for-phone-only {
      top: 6px;
    }
  }

  nav {
    align-self: flex-end;
    display: flex;

    a {
      color: white;
      margin: 0 34px;
      font-size: 14px;
    }

    a.selected{
      opacity: 0.34;
    }
    
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }


}