@import "../../breakpoints";
@import "../../shared.scss";

.gallery {

  ul {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .items{
    display: flex;
    flex-wrap: wrap;
  }

  img{
    box-shadow: rgba(92, 92, 92, 0.50) 0 20px 50px 0px;
  }

  .item{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .title{
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
  }

  .subtitle{
    font-weight: 300;
    font-size: 14px;
    margin-top: 5px;
    color: #949494;
  }

  a{
    color: white;
    transition: all 0.2s ease-out;
    
    &:hover{
      color: #f2395a;
    }
  }


  li{
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;

    img{
      width: 100%;
    }

    .image{
      padding-bottom: 62%;
      background-size: cover;
      background-position: center;

      &:after {
        content: "";
        padding-bottom: 50%;
      }
    }

    a {
      width: 100%;
      height: 100%;
      display: block;
    }

    .vaporFrame{
      position: relative;
      @include vaporFrame();
    }


  }
}

:not(.touch) .gallery .item a:hover{
  .vaporFrame:before {
    filter: hue-rotate(40deg);
  }
  .vaporFrame .frameContent {
    transform: scale(0.98);
    filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(300deg);
  }
}

// large/medium is for "types of galleries"
// responsiveness is implemented within

.large{

  .item{
       flex: 1 0 45%;
       max-width: 50%;
     }

  .item:nth-child(2n){
    margin-right: 0;
  }


  @include for-tablet-landscape-up{
    .item{
      flex: 1 0 21%;
      max-width: 25%;
    }

    .item:nth-child(2n){
      margin-right: 30px;
    }

    .item:nth-child(4n){
      margin-right: 0;
    }
  }
}


.medium{
  .item{
    flex: 1 0 30%;
    max-width: 33%;
  }

  .item:nth-child(3n){
    margin-right: 0;
  }

  @include for-phone-only {

    .item{
      flex: 1 0 45%;
      max-width: 50%;
    }

    .item:nth-child(3n){
      margin-right: 20px;
    }

    .item:nth-child(2n){
      margin-right: 0;
    }

  }

  @include for-tablet-landscape-up{
    .item{
      flex: 1 0 18%;
      max-width: 20%;
    }

    .item:nth-child(3n){
      margin-right: 20px;
    }

    .item:nth-child(5n){
      margin-right: 0;
    }
  }
}