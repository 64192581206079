@mixin neon{
  animation: neon3 1.5s ease-in-out infinite alternate;
}

@keyframes neon3 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FFDD1B, 0 0 70px #FFDD1B, 0 0 80px #FFDD1B, 0 0 100px #FFDD1B, 0 0 150px #FFDD1B;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #FFDD1B, 0 0 35px #FFDD1B, 0 0 40px #FFDD1B, 0 0 50px #FFDD1B, 0 0 75px #FFDD1B;
  }
}

@mixin headerDivider{
  &:after{
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right,  rgba(173,85,177,0) 0%, rgba(173,85,177,1) 40%, rgba(173,85,177,1) 60%, rgba(173,85,177,0) 100%);
    z-index: 5
  }
}

$scale: 1.04;
@keyframes vibrate-2 {
  0% {
    transform: scale($scale) translate(0);
  }
  20% {
    transform: scale($scale) translate(2px, -2px);
  }
  40% {
    transform: scale($scale * 1.03) translate(2px, 2px);
  }
  60% {
    transform: scale($scale) translate(-2px, 2px);
  }
  80% {
    transform: scale($scale * 0.97) translate(-2px, -2px);
  }
  100% {
    transform: scale($scale) translate(0);
  }
}


@mixin vaporButton($color: 'f25fe7'){
  position: relative;
  padding: 0;
  border: none;
  margin: 20px 0;
  align-self: center;
  display: inline-flex;
  background: none;
  cursor: pointer;
  font-size: 16px;
  //overflow: hidden;

  &:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border: 18px solid rgba(0,0,0,0.01);
    box-sizing: border-box;
    border-image: url("data:image/svg+xml,%3Csvg  width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-width='4' fill='transparent' stroke='%23#{$color}' d='M0 20 L20 0 L190 0 L200 10%0AL200 180 L180 200 L10 200 L0 190 L0 20' /%3E%3C/svg%3E");
    border-image-repeat: stretch;
    /*   border-image-width: 20; */
    border-image-slice: 35;
    border-image-outset: 4px;
  }

  &:after{
    content: '';
    position: absolute;
    width: calc(100% + 8px);
    height: calc(100% + 7px);
    transform: scaleY(0);
    top: -3px;
    left: -4px;
    background: unquote('##{$color}');
    opacity: 0.75;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);;
    clip-path: polygon(0 10px, 10px 0, calc(100% - 5px) 0, 100% 6px,
            100% calc(100% - 10px), calc(100% - 10px) 100%, 5px 100%, 0 calc(100% - 6px));
  }

  &:hover:after{
    transform: scaleY(1);
  }


  .frameContent{
    position: relative;
    width: 100%;
    height: calc(100% + 4px);
    padding: 10px 80px;
    z-index: 5;
    //clip-path: polygon(0 15px, 15px 0, calc(100% - 6px) 0, 100% 6px,
    //        100% calc(100% - 15px), calc(100% - 15px) 100%, 6px 100%, 0 calc(100% - 6px));
  }

  color: white;
}


@mixin vaporFrame($color: 'b957ad'){

  &:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border: 18px solid rgba(0,0,0,0.01);
    box-sizing: border-box;
    border-image: url("data:image/svg+xml,%3Csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-width='1.6' fill='transparent' stroke='%23#{$color}' d='M0 20 L20 0 L190 0 L200 10%0AL200 180 L180 200 L10 200 L0 190 L0 20' /%3E%3C/svg%3E");
    border-image-repeat: stretch;
    /*   border-image-width: 20; */
    border-image-slice: 20;
    border-image-outset: 4px;
    transition: all 0.2s ease-out;
  }

  .frameContent{
    width: 100%;
    height: 100%;
    clip-path: polygon(0 15px, 15px 0, calc(100% - 6px) 0, 100% 6px,
            100% calc(100% - 15px), calc(100% - 15px) 100%, 6px 100%, 0 calc(100% - 6px));
    transition: all 0.2s ease-out;
  }

  //.glow{
  //
  //  &:before, &:after{
  //    content: "";
  //    width: 100%;
  //    height: 100%;
  //    top: 0;
  //    position: absolute;
  //    z-index: -1;
  //    filter: blur(10px);
  //    transform: scale(1.1);
  //    opacity: 0.5;
  //  }
  //
  //  &:before{
  //    background: #b957ad;
  //    animation: vibrate-2 3.4s linear infinite both;
  //  }
  //
  //  &:after{
  //    background: red;
  //    animation: vibrate-2 1.8s linear infinite both;
  //    animation-delay: 2s
  //  }
  //}
}

.touch .vaporButton:after{
  display: none;
}