@import "../../shared.scss";
.fileUploadInput{
	color: #acacac;
	margin: 10px 0;

	.inputfile {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.inputfile + label {
		max-width: 80%;
		font-size: 16px;
		/* 20px */
		font-weight: 300;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
		display: inline-block;
		overflow: hidden;
		padding: 0.625rem 1.25rem;
		/* 10px 20px */
	}

	.inputfile:focus + label,
	.inputfile.has-focus + label {
		outline: 1px dotted #000;
		outline: -webkit-focus-ring-color auto 5px;
	}

	.inputfile + label * {
		/* pointer-events: none; */
		/* in case of FastClick lib use */
	}

	.inputfile + label svg {
		width: 1em;
		height: 1em;
		vertical-align: middle;
		fill: currentColor;
		margin-top: -0.25em;
		/* 4px */
		margin-right: 0.25em;
		/* 4px */
	}


	.inputfile-2 + label {
		
		border: 1px solid currentColor;
	}

	.inputfile-2:focus + label,
	.inputfile-2.has-focus + label,
	.inputfile-2 + label:hover {
		color: #c4a9b1;
	}

	.inputfile-2.has-focus {
		&:invalid {
			& + label {
				overflow: visible;
				&:after {
					content: 'CV Required';
					position: absolute;
					left: 0;
					bottom: -5px;
					color: #f2395a;
					font-weight: bold;
					transform: translateY(100%);
				}
			}
		}
	}
}