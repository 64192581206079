@import "../../breakpoints";
@import "../../shared";

.sonorous {
  position: relative;
  background-color: black;
  padding-top: 90px;

  .content{
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center 170px, center;
    position: relative;

    @include for-desktop-up {
      background-image: url('../../components/splash/background.png');
    }
  }

  header {
    position: relative;
    z-index: 10;
    max-width: 900px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: center;
    }

    img {
      margin: 30px;
      width: 70%;

      @include for-tablet-portrait-up{

      }
    }

    .text{
      display: flex;
      flex-direction: column;
      width: 100%;
      @include for-tablet-portrait-up {
        padding-left: 30px;
        width: auto;
      }
    }

    h1 {
      color: #fbb03b;
      font-size: 48px;
      font-weight: 800;
      text-transform: uppercase;
      margin: 0;
      text-align: center;

      @include for-tablet-portrait-up {
        font-size: 55px;
        text-align: left;
      }

      span {
        font-size: 30px;
        color: white;
        letter-spacing: 2px;
      }

    }

    h2 {
      color: white;
      font-size: 16px;
      font-weight: 200;
      text-align: justify;

      @include for-tablet-portrait-up{
        font-size: 20px;
        text-align: left;
      }
    }
  }

  .codepen {
    position: relative;
    width: 100%;
    height: 400px;
    z-index: 10;
    margin: 30px 0;

    @include for-tablet-portrait-up{
      height: 600px;
    }
  }

  .defs{
    position: absolute;
    visibility: hidden;
    left: 0;
  }
}

section.features{
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,1) 80% ,  rgba(0,0,0,1) 100%),
    url(terrain_bg.jpg);
    background-position: center top,
    top;
    background-repeat: no-repeat;
    background-size: 100%;

    z-index: 1;
  }

  .content{
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding-top: 50px;
    flex-direction: column;
    align-items: center;
  }

  @include for-desktop-up {
    .content{
      flex-direction: row;
    }
  }

  .item-container {


    .icon{
      width: 230px;
      position: relative;

      svg{
        position: relative;
        z-index: 10;
      }

      .hex{
        stroke: #b75ab9;
        stroke-width: 4px;
      }

      img{
        position: absolute;
        width: 30%;
        left: 50%;
        top: 117px;
        transform: translateX(-50%) translateY(-50%);
        z-index: 15;
      }

      .caption{
        position: absolute;
        top: 200px;
        width: 200px;
        left: calc(50% - 100px);
      }

    }

    .item {
      width: 500px;
      box-sizing: border-box;
      text-align: left;
      margin-left: -30px;
      margin-bottom: 40px;

      @include for-phone-only {
        width: 100%;
        text-align: center;
        margin-left: 0;
      }

      .title {
        position: relative;
        margin-top: 75px;
        margin-bottom: 25px;
        font-size: 31px;
        font-weight: 300;

        @include for-phone-only {
          margin-top: 0;
          @include headerDivider;
        }

      }

      .description{
        font-size: 17px;
        color: white;
        line-height: 1.2;
        font-weight: 200;

      }
    }

  }

}

section.menu{
  .content{
    position: relative;
    z-index: 10;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin: 60px auto;
    justify-content: space-between;
    font-size: 23px;
    padding: 0;

    @include for-desktop-up {
      flex-direction: row;
      min-width: 400px;
      max-width: 550px;
    }

    a{
      color: white;
      text-decoration: underline;
    }

    li{
      margin: 10px;

      @include for-desktop-up {
        margin: 0;
      }
    }

  }
}

section.shoutout{
  a.logo{
    position: relative;
    top: 2px;
    color: rgba(255,255,255,0);
    font-size: 30px;  
    background-size: 100%;  
    background-repeat: no-repeat;
    background-image: url('../../components/header/ekoengineering_logo.svg');
    margin: 0 6px;

    span {
      padding-left: 5px;
      font-size: 16px;
    }

  }
}

section.moreOpenSource{
  margin: 50px 0;
  font-size: 12px;

  a{
    color: #fff;
    text-decoration: underline;
  }
}

.github-corner{
  position: absolute;
  top:0;
  right:0;
  z-index: 500;
}

.github-corner svg{
  fill:#fff; color:#151513; position: absolute; top: 0; border: 0; right: 0;
}

.github-corner:hover .octo-arm{
  animation:octocat-wave 560ms ease-in-out
}
@keyframes octocat-wave{
  0%,100%{transform:rotate(0)}20%,60%{transform:rotate(-25deg)}40%,80%{transform:rotate(10deg)}
}

@media (max-width:500px){
  .github-corner:hover .octo-arm{animation:none}.github-corner .octo-arm{animation:octocat-wave 560ms ease-in-out}
}
