@import "../../shared.scss";
@import "../../breakpoints.scss";

.tech {
  position: relative;

  $topOffset: 80px;

  .reel{
    position: absolute;
    top: $topOffset;
    left: 0;
    width: 100%;
    height: calc(100% - #{$topOffset});
    object-fit: cover;
    z-index: 1;

    &:before, &:after, .wash, .wash:before, .wash:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .wash{
      position: absolute;
      top: $topOffset;
      left: 0;
      width: 100%;
      height: calc(100% - #{$topOffset});

      &:before{
        background-color: #5d0e4d;
        mix-blend-mode: screen;
        z-index: 5;
      }

      &:after{
        background-image: url(./paper_bg.jpg);
        background-size: cover;
        mix-blend-mode: overlay;
        opacity: 0.3;
        z-index: 5;
      }
    }

    &:before{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 3'%3E%3Crect fill='rgba(0,0,0,1)' width='2' height='1' /%3E%3C/svg%3E");
      background-size: 2px;
      z-index: 10;
    }

    &:after{
      background: rgba(0,0,0,0.5);
      z-index: 15;

    }




    video{
      width: 100%;
      height: calc(100% - #{$topOffset} - 1px);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 160px;
    z-index: 5;
    align-items: center;

  }

  .items{
    display: flex;
    flex-wrap: wrap;
    max-width: 950px;
    text-align: left;
    justify-items: center;
    margin-top: 400px;

    @include for-phone-only{
      flex-direction: column;
      margin-top: 0;
    }
    
  }

  .item {
    box-sizing: border-box;
    width: 47%;
    margin-bottom: 60px;
    font-weight: 200;

    h5{
      color: white;
    }

    @include for-phone-only {
      width: 100%;
      max-width: 600px;
    }

    a{
      color: #f8aa1a;
      white-space: nowrap;
      transition: all 0.2s ease-out;

      &:hover{
        color: var(--color2);
      }
    }
  }

  .item:nth-child(2n - 1){
    margin-right: 30px;
  }

  .item:nth-child(2n){
    page-break-after: always;
    break-after: always;
  }

  .text{
    font-size: 17px;
    color: white;
    line-height: 25px;
  }

  h4{
    margin-top: -100px;
  }

  h5, h5 a{
      color: #f8aa1a;
      transition: all 0.15s ease-in;
      text-shadow: 0 0 0px rgba(255,255,255,0),
      0 0 0px rgba(255,255,255,0),
      0 0 0px rgba(255,255,255,0),
      0 0 0px rgba(255,255,255,0),
      0 0 0px rgba(255,255,255,0),
      0 0 0px rgba(255,255,255,0),
      0 0 0px rgba(255,255,255,0),
      0 0 0px rgba(255,255,255,0);
      font-size: 30px;
  }

  //h5 a:hover{
  //  color: white;
  //  @include neon;
  //}

  // preview

  .preview {
    box-shadow: #60082a 0 15px 1500px 15px;

    video {
      width: 100%;
    }
  }

}