@import "../../breakpoints.scss";
@import "../../shared.scss";

.careers{
  padding-top: 150px;
  position: relative;

  @include for-phone-only {
    .content {
      padding: 0 15px;
    }
  }
  
  .filters{
    position: absolute;
    visibility: hidden;
    left: -500%;
  }

  &:after{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    z-index: -1;
    opacity: 0.5;
    background-image: url(./careers_bg.jpg);
    background-size: 300%;
    background-position: center bottom;
    background-repeat: no-repeat;

      @include for-tablet-landscape-up {
        background-size: 100%;
      }
    }


  .content{
    display: flex;
    flex-direction: column;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }

  .positions{
    display: flex;
    overflow: hidden;
    padding-bottom: 40px;

    align-self: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    @include for-tablet-landscape-up{
      flex-direction: row;
      flex-wrap: wrap;
    }
    
  }


  .position {
    display: flex;
    width: 100%;
    margin-bottom: -50px;
    min-width: 300px;

    @include for-phone-only {
      margin-bottom: -40px;
      margin-left: -40px;
    }


    @include for-tablet-landscape-up{
      width: 45%;

      // if odd number, align last one to the left
      &:nth-child(2n-1):last-child{
        margin-right: 45%;
      }
    }

    &:nth-child(2n - 1){
      flex-direction: row-reverse;

      @include for-phone-only {
        margin-bottom: -40px;
        margin-right: -40px;
        margin-left: 0;
      }

      .item{
        margin-left: 0px;
        margin-right: -30px;
        text-align: right;
      }
      
      .icon:after{
        right: 80%;
        left: auto;
        background: linear-gradient(to left,  rgba(173,85,177,1) 0%,rgba(173,85,177,0) 100%);
      }
    }
    
    &:last-child .icon:before{
      display: none;
    }


    .icon{
      width: 230px;
      min-width: 230px;
      position: relative;
      pointer-events: none;

      @include for-phone-only {
        min-width: 200px;
      }

      svg{
        position: relative;
        z-index: 10;
      }

      .hex{
        stroke: #b75ab9;
        stroke-width: 4px;
        fill: rgba(0,0,0,0.5);
      }

      img{
        position: absolute;
        width: 30%;
        left: 50%;
        top: 115px;
        transform: translateX(-50%) translateY(-50%);
        z-index: 15;
      }

      &:after{
        content: "";
        position: absolute;
        height: 2px;
        width: 400px;
        top: 113.5px;
        left: 80%;
        background: linear-gradient(to right,  rgba(173,85,177,1) 0%,rgba(173,85,177,0) 100%);
        z-index: 5;
        @include for-phone-only {
          top: 99px;
         }

      }
    }

    .item {
      width: 265px;
      box-sizing: border-box;
      text-align: left;
      margin-left: -30px;
      margin-bottom: 40px;

      @include for-phone-only {
        width: 100%;
        text-align: left;
        margin-left: -20px;
      }

      .title {
        position: relative;
        margin-top: 75px;
        margin-bottom: 10px;
        font-size: 31px;
        font-weight: 300;
        font-family: "Stolzl-Light";

        @include for-phone-only {
          font-size: 14px;
          margin-top: 76px;
        }

      }

      .description {
        font-size: 17px;
        color: white;
        line-height: 1.2;
        font-weight: 200;

        @include for-phone-only {
          font-size: 14px;
        }

        a {
          color: var(--color3);
          &:hover{
            color: var(--color2);
          }
        }

      }
    }

  }


  .title{
    font-weight: bold;
  }

  .location{
    font-size: 14px;
  }

  em{
    display: block;
    text-decoration: none;
    font-weight: bold;
    margin: 10px 0px;
  }

  .sendResumeBtn{
    @include vaporButton('f2395a');
    margin-bottom: 70px;
    
    align-self: center;
    display: inline-flex;
    max-width: 450px;
    margin-top: 70px;
    text-align: left;
    align-self: center;

  }

  .ctaDescription{
    max-width: 500px;
    margin-top: 40px;
    text-align: center;
    align-self: center;
  }

  .ctaButton{
    align-self: center;
  }

}