@import "../../breakpoints.scss";
@import "../../shared.scss";

.contact {
  padding-top: 50px;

  .content {
    &.success-hide form { padding-top: 0; }
    &.success-hide h4,
    &.success-hide .form-field,
    &.success-hide .sendButton {
      display: none;
    }
  }
  
  form{
    width: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: flex-start;
    align-items: center;
    padding-top: 50px;

    >* {
      width: 100%;
      max-width: 600px;
    }
  }

  input, textarea{
    background: black;
    color: white;

  }

  .align-center {
    text-align: center;
  }

  .form-field {
    position: relative;
    margin: 0;
    margin-bottom: 50px;
    text-align: left;

    .input-text {
      padding: 0;
      font-family: "Raleway", sans-serif;
      //text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 15px;
      width: 100%;
      border-width: 0 0 1px 0;
      border-color: rgba(white, 0.5);
      transition: all 200ms ease-in-out;

      &:focus {
        outline: none;
      }
    }

    .label {
      position: absolute;
      font-family: "Raleway", sans-serif;
      //text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 15px;
      color: #888;
      pointer-events: none;
      top: -25px;
      left: 0;
      transition: all 200ms ease-in-out;
    }

    &.cvRow{
      display: flex;
      flex-direction: column;
      label{
        position: relative;
      }
    }

    .input-cv + .label{
      position: relative;
      top: 0;
      margin-bottom: 10px;
    }

    textarea {
      resize: none;
      padding: 0;
      height: 100px;
    }

    select {
      padding: 5px 10px;
      font-family: inherit;
      border: solid 1px #acacac;
      color: #acacac;
      background-color: transparent;
      cursor: pointer;

      option {
        color: #acacac;
        background-color: black;
      }
    }
  }

  .input-text:placeholder-shown + .label {
    top: -10px;
  }


  .sendButton{
    @include vaporButton('f2395a');
    width: auto;
  }

  .formMessage {
    &.success {

      margin-bottom: 140px;

      .thank-you-corgi {
        width: 100%;
      }

      .thank-you-title {
        font-size: 39px;
        font-weight: 700;
        margin-top: -40px;

        .highlighted {
          color: var(--color3);
        }

        @include for-phone-only {
          font-size: 34px;
          margin-top: -20px;
        }
      }

      .thank-you-subtitle {
        font-size: 25px;
        font-weight: 200;
        margin-bottom: 60px;

        @include for-phone-only {
          font-size: 20px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .cta-message {
        font-size: 14px;
        margin-bottom: 15px;
      }

      .cta-links {
        display: flex;
        justify-content: space-between;

        a {
          color: white;
          font-weight: 700;
          &:before {
            font-family: "Font Awesome 5 Brands";
            margin-right: 5px;
          }
          &:hover {
            color: var(--color2);
          }

          &:last-child {
            margin-bottom: 0;
          }

          @include for-phone-only {
            display: inline-block;
            margin-bottom: 15px;
          }
        }

        .twitter {
          &:before {
            content: "\f099";
          }
        }

        .medium {
          &:before {
            content: "\f3c7";
          }
        }

        .github {
          &:before {
            content: "\f09b";
          }
        }

        @include for-phone-only {
          flex-direction: column;
        }
        
      }

      @include for-phone-only {
        margin-bottom: 0;
       }

    }
  }
}