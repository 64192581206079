@import "../../breakpoints";

.investors {
  $itemMargin: 20px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .title {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
  }
  

  .items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    a {
      flex: 1 0 8%;
      max-width: 10%;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          height: 100px;
          margin-right: $itemMargin;
          background-color: transparent;
          margin-bottom: 25px;
          transition: .2s ease transform;
  
          img {
            max-width: 56px;
            max-height: 56px;
          }

        }

        @media screen and (max-width: 1070px) {
          flex: 1 0 15%;
          max-width: 16.6%;

          &:nth-child(6n) {
            .item {
              margin-right: 0;
            }
          }
        }

        @media screen and (max-width: 700px) {
          flex: 1 0 20%;
          max-width: 20%;

          &:nth-child(6) {
            .item {
              margin-right: 20px;
            }
          }

          &:nth-child(5n) {
            .item {
              margin-right: 0;
            }
          }
        }

        @media screen and (max-width: 510px) {
          flex: 1 0 25%;
          max-width: 25%;

          &:nth-child(5n) {
            .item {
              margin-right: 20px;
            }
          }

          &:nth-child(4n) {
            .item {
              margin-right: 0;
            }
          }
        }

        @media screen and (max-width: 410px) {

          flex: 1 0 33%;
          max-width: 33%;

          &:nth-child(4n) {
            .item {
              margin-right: 20px;
            }
          }

          &:nth-child(3n) {
            .item {
              margin-right: 0;
            }
          }
        }

      }
    }

}

.App:not(.touch) .investors .items .item{
  &:hover {
    transform: scale(1.2);
  }
}