.position{
	.content{
		padding-top: 30px;
		text-align: left;
	}

	hr{
		margin: 40px 0;
	}

	.legal{
		margin: 40px 0;
		font-size: 10px;
		color: gray;
	}
}