.footer {

  .content {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      margin: 80px auto 20px auto;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: left;
    margin-right: 150px;

    @media screen and (max-width: 984px) {
      margin-bottom: 40px;
    }
  }

  li {
    margin-bottom: 10px;
  }

  .title {
    font-weight: bold;
    color: white;
    font-size: 12px;

     &:hover {
       color: #96263a;
     }

     .title-icon {
       margin-right: 7px;
     }
  }

}