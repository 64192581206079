@import "./fonts";
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400&display=swap');
@import "breakpoints";
@import "./shared";

:root{
  --color1: #f25fe7;
  --color2: #f2395a;
  --color3: #f8aa1a;
}

body{
  position: relative;
  &:after {
    content: "";
    background-image: url(media/space_bg.jpg);
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -5;
  }
}

h1, h2, h3, h4, h5{
  font-family: 'Raleway', sans-serif;
  color: white;
}

h4{
  font-size: 39px;
  position: relative;
  @include headerDivider;
}

h5{
  font-size: 20px;
  margin: 10px 0;
  font-weight: bold;
}

section {
  width: 100%;

  .content{
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 25px;

    @include for-desktop-up{
      padding: 0 0;
    }
  }
}

a{
  text-decoration: none;
  color: #f2395a;
}


.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  path {
    fill: white;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ctaButton {
  position: relative;
  color: white;
  text-decoration: none;
  border: 1px solid var(--color1);
  letter-spacing: 2px;
  padding: 10px 80px;
  text-transform: uppercase;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 15px;
  background: transparent;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  z-index: 1;

  &:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background: var(--color1);
    z-index: -1;
    transition: all 0.3s;
  }

  &:hover:after{
      height: 100%;
  }
}

.bm-burger-button {
  position: fixed;
  height: 20px;
  width: 20px;
  top: 30px;
  right: 27px;

  button {
    backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
  }

  @media only screen and (min-width: 1025px) {
    display: none;
  }

}

.menu-open {
  overflow: hidden; // Prevents scrolling inside mobile menu
}

.bm-menu-wrap {
  background-color: black;
  padding: 110px 0;
  width: 100% !important;

  .bm-cross-button {
    height: 20px !important;
    width: 20px !important;
    top: 38px !important;
    right: 50px !important;
    
    button {
      backface-visibility: hidden;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;
      outline: 0;
    }
  }

  .bm-item {
    color: white;
    padding: 20px 0;
    font-weight: bold;
    font-size: 20px;
    backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    outline: 0;

    &:hover {
      font-weight: bold;
    }
  }


}

.fullscreen{
  z-index: 1000;
  position: relative;
}