@import "../../breakpoints";

.landingPageDev{
  .splash {
    background-image: url('../../components/splash/background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 50px;
    position: relative;

    @include for-phone-only{
      background-position: center -60px;
    }


    .content {
      position: relative;
      display: flex;
      //visibility: hidden;
      padding-top: 140px;
      padding-bottom: 100px;
      mix-blend-mode: screen;
      z-index: 5;

      flex-direction: column;
      align-items: center;
      pointer-events: none;

      @include for-phone-only{
        padding-top: 80px;
      }

      @include for-tablet-landscape-up{
        flex-direction: row;
        align-items: normal;
      }

      @include for-desktop-up{
        flex-direction: row;
        align-items: normal;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex: 1;

      h3{
        margin-top: 30px;
        font-size: 38px;
        text-shadow:
                -1px -1px 0 #000,
                1px -1px 0 #000,
                -1px 1px 0 #000,
                1px 1px 0 #000;


        @include for-tablet-landscape-up{
          margin-top: 0px;
        }

        @include for-phone-only{
          margin-top: 5px;
        }

        em{
          color: var(--color3);
        }
      }


    }

    .left {
      position: relative;
      font-size: 0;
      flex: 1;
      width: 90%;
      background-image: url('../../components/splash/arcade.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;

      &:before{
        display: block;
        content: "";
        width: 100%;
        padding-bottom: 80%;
      }

      .titleContainer{
        position: absolute;
        display: flex;
        flex-direction: column;

        align-items: center;
        align-content: center;
        justify-content: center;
        top: 27%;
        left: 41%;
        width: 47%;
        //background: rgba(100,200,50,1);


        @include for-phone-only{

        }

        @include for-tablet-portrait-up{

        }

        @include for-tablet-landscape-up{

        }
      }

      h1 {
        display: inline-block;
        width: 75%;
        padding-bottom: 32%;
        margin-bottom: 0;
        font-size: 12px;
        background-image: url('../../components/splash/EkoLogo.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        color: rgba(255,255,255, 0);


        @include for-tablet-portrait-up{
          margin-top: 12%;
        }


        @include for-phone-only{
          margin-top: 14%;
        }
      }

      h2 {
        position: relative;
        margin: 0;
        padding-bottom: 10%;
        text-transform: uppercase;
        width: 100%;
        font-size: 4.2vw;
        margin-top: 0.2vw;
        will-change: text-shadow;

        //@include neon;
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FFDD1B, 0 0 70px #FFDD1B, 0 0 80px #FFDD1B, 0 0 100px #FFDD1B, 0 0 150px #FFDD1B;


        @include for-tablet-portrait-up{
          font-size: 32px;
          margin-top: 3px;
        }

        @include for-tablet-landscape-up{
          font-size: 24px;
        }

        @include for-desktop-up{
          font-size: 36px;
        }
      }


    }

    h2{
      font-size: 52px;
      font-weight: 400;
      margin: 0;


      em{
        color: #ebab19;
        text-transform: uppercase;
        font-style: normal;
      }

      &.positionTitle em{
        text-transform: none;
      }
    }

    h3{
      text-align: left;
      font-size: 26px;
      font-weight: 300;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  .points{
    position: relative;
    padding-bottom: 60px;

    a{
      color: var(--color3);
    }

    &:after{
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(to right,  rgba(173,85,177,0) 0%, rgba(173,85,177,1) 40%, rgba(173,85,177,1) 60%, rgba(173,85,177,0) 100%);
      z-index: 5
    }

    .content{
      max-width: 730px;
    }

    h5{
      font-family: "Stolzl-Regular";
      font-weight: normal;
      font-size: 22px;
    }

    text-align: left;
    font-family: "Stolzl-Light";
    font-size: 16px;

    li{
      margin: 8px 0;
    }

    .wrapup{
      padding-top: 20px;
      font-family: "Stolzl-Regular";
      font-size: 18px;
    }
  }

  section.positions{
    margin-top: 30px;

    .filters{
      position: absolute;
      visibility: hidden;
      left: -500%;
    }

    ul.positionList{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0;
      list-style-type: none;

    }

    h4{
      margin-top: 80px;
      margin-bottom: 0px;
      font-size: 22px;
      font-family: "Stolzl-Regular";
      font-weight: normal;
      &:after{
        display: none;
      }
    }

    .position {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 200px;


      .icon{
        min-width: 230px;
        position: relative;
        pointer-events: none;

        svg{
          position: relative;
          z-index: 10;
        }

        .hex{
          stroke: #b75ab9;
          stroke-width: 4px;
          fill: rgba(0,0,0,0.5);
        }

        img{
          position: absolute;
          width: 30%;
          left: 50%;
          top: 115px;
          transform: translateX(-50%) translateY(-50%);
          z-index: 15;
        }

      }

      .item {
        width: 200px;
        box-sizing: border-box;
        text-align: center;


        .title {
          position: relative;
          font-size: 28px;
          font-weight: 300;
          margin-top: -40px;
          font-family: "Stolzl-Light";

        }

        .description{
          font-size: 17px;
          color: white;
          line-height: 1.2;
          font-weight: 200;

          a{
            color: var(--color3);
            &:hover{
              color: var(--color2);
            }
          }

        }
      }

    }


    .caption1, .caption2{
      font-size: 24px;
      font-family: "Raleway";
    }

    .caption1{
      color: var(--color3);
      margin-top: 40px;
    }

    .caption2{
      margin-top: 6px;
    }
  }
}
