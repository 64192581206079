@import "../../shared.scss";
@import "../../breakpoints.scss";

.splash {
	background-image: url('./background.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding-bottom: 50px;
	position: relative;

	@include for-phone-only{
		background-position: center -60px;
	}

	canvas{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: black;
		z-index: 1;
		mix-blend-mode: screen;
	}

	.content {
		position: relative;
		display: flex;
		//visibility: hidden;
		padding-top: 140px;
		padding-bottom: 100px;
		mix-blend-mode: screen;
		z-index: 5;

		flex-direction: column;
		align-items: center;
		pointer-events: none;

		@include for-phone-only{
			padding-top: 80px;
		}

		@include for-tablet-landscape-up{
			flex-direction: row;
			align-items: normal;
		}

		@include for-desktop-up{
			flex-direction: row;
			align-items: normal;
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;

		h2{
			margin-top: 30px;
			font-size: 43px;

			@include for-tablet-landscape-up{
				margin-top: 0px;
			}
			
			@include for-phone-only{
				margin-top: 5px;
			}
		}

		h3{
			font-size: 20px;
		}

	}

	.left {
		position: relative;
		font-size: 0;
		flex: 1;
		width: 90%;
		background-image: url('./arcade.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;

		&:before{
			display: block;
			content: "";
			width: 100%;
			padding-bottom: 80%;
		}

		.titleContainer{
			position: absolute;
			display: flex;
			flex-direction: column;
			
			align-items: center;
			align-content: center;
			justify-content: center;
			top: 27%;
			left: 41%;
			width: 47%;
			//background: rgba(100,200,50,1);


			@include for-phone-only{
				
			}

			@include for-tablet-portrait-up{

			}

			@include for-tablet-landscape-up{

			}
		}

		h1 {
			display: inline-block;
			width: 75%;
			padding-bottom: 32%;
			margin-bottom: 0;
			font-size: 12px;
			background-image: url('./EkoLogo.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			color: rgba(255,255,255, 0);


			@include for-tablet-portrait-up{
				margin-top: 12%;
			}


			@include for-phone-only{
				margin-top: 14%;
			}
		}

		h2 {
			position: relative;
			margin: 0;
			padding-bottom: 10%;
			text-transform: uppercase;
			width: 100%;
			font-size: 4.2vw;
			margin-top: 0.2vw;
			will-change: text-shadow;

			//@include neon;
			text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #FFDD1B, 0 0 70px #FFDD1B, 0 0 80px #FFDD1B, 0 0 100px #FFDD1B, 0 0 150px #FFDD1B;


			@include for-tablet-portrait-up{
				font-size: 32px;
				margin-top: 3px;
			}

			@include for-tablet-landscape-up{
				font-size: 24px;
			}

			@include for-desktop-up{
				font-size: 36px;
			}
		}
		
		
	}

	h2{
		font-size: 52px;
		font-weight: 400;
		margin: 0;


		em{
			color: #ebab19;
			text-transform: uppercase;
			font-style: normal;
		}

		&.positionTitle em{
			text-transform: none;
		}
	}

	h3{
		font-size: 26px;
		font-weight: 300;
		max-width: 500px;
	}

	.zoomInButton{
		pointer-events: all;
		margin-top: 15px;
		@include vaporButton()
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 0;
	}
}