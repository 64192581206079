@import "../../breakpoints";

.projects{
	position: relative;
	overflow: hidden;

	.hexGrid{
		//position: absolute;
		display: flex;
		flex-wrap: wrap;
		width: 120vw;
		margin: 0 0;
		overflow: hidden;
		font-size: 15px;
		list-style-type: none;
		padding: 0;
		padding-top: 30px;
		padding-bottom: 3.5vw;
		z-index: 1;
	}

	.hexText{
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		z-index: 20;
		opacity: 0;
		transition: all 0.2s ease-out;

		.title, .subtitle {
			position: relative;
		}
	}

	.title{
		font-size: 14px;
	}

	.subtitle{
		color: #e2e2e2;
		font-size: 12px;
		margin-top: 4px;
	}

	.hex{
		transition: all 0.4s ease-out;
	}

	@include for-phone-only {
		.hexGrid {
			width: 280vw;
			margin-left: calc(-50% - 52vw);
			padding-bottom: 5.5vw;
		}

		.hex {
			width: 23.3vw;
		}
		.hex:nth-child(23n+13){ /* first hexagon of even rows */
			margin-left: 11.65vw;  /* = width of .hex / 2  to indent even rows */
		}
	}


	@include for-tablet-portrait-up {
		.hexGrid {
			width: 240vw;
			margin-left: calc(-50% - 30vw);
		}

		.hex {
			width: 20vw;
		}
		.hex:nth-child(23n+13){ /* first hexagon of even rows */
			margin-left: 10vw;  /* = width of .hex / 2  to indent even rows */
		}
	}



	@include for-tablet-landscape-up {
		.hexGrid {
			width: 180vw;
			margin-left: calc(-50%);
		}

		.hex {
			width: 15vw;
		}
		.hex:nth-child(23n+13){ /* first hexagon of even rows */
			margin-left: 7.5vw;  /* = width of .hex / 2  to indent even rows */
		}
	}

	@include for-desktop-up {
		.hexGrid {
			width: 120vw;
			margin-left: calc(-50% + 35vw);
		}

		.hex {
			width: 10vw;
		}
		.hex:nth-child(23n+13){ /* first hexagon of even rows */
			margin-left: 5vw;  /* = width of .hex / 2  to indent even rows */
		}
	}

	.hexIn{
		position: absolute;
		width:96%;
		padding-bottom: 110.851%; /* =  width / sin(60) */
		margin:0 2%;
		overflow: hidden;
		visibility: hidden;
		outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
		transform: rotate3d(0,0,1,-60deg) skewY(30deg);
		background: grey;
		will-change: transform;
	}
	
	.hex{
		position: relative;
		visibility:hidden;
		outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
	}
	.hex::after{
		content:'';
		display:block;
		padding-bottom: 86.602%;  /* =  100 / tan(60) * 1.5 */
	}

	.hexIn * {
		position: absolute;
		visibility: visible;
		outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
	}
	
	.hexLink, .hexDud {
		display:block;
		width: 100%;
		height: 100%;
		text-align: center;
		color: #fff;
		overflow: hidden;
		transform: skewY(-30deg) rotate3d(0,0,1,60deg);
	}

	.hexLink:before{
		content: "";
		left: 0;
		top: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(165, 80, 199, 0);
		z-index: 10;

		transition: all 0.3s ease-in;;
	}

	.hexDud{
		background: #202020;
	}

	.hex img {
		left: -100%;
		right: -100%;
		width: auto;
		height: 100%;
		margin: 0 auto;
		-webkit-transform: rotate3d(0,0,0,0deg);
		-ms-transform: rotate3d(0,0,0,0deg);
		transform: rotate3d(0,0,0,0deg);
	}

	.hexGrid.active {
		.hex {
			opacity: 0.8;
		}

		.hex.hover {
			opacity: 1;

			.hexText {
				opacity:1;
			}

			.hexLink:before {
				background: rgba(159, 52, 199, 0.8);
			}
		}

	}
}

:not(.touch) .projects{

}

.touch .projects{
	.hexGrid {

		.hex {
			opacity: 1;

			.title{
				font-size: 13px;
				margin-bottom: 2px;
			}

			.subtitle{
				margin-top: 0px;
				font-size: 10px;
			}

			.hexText {
				opacity:1;
			}

			.hexLink:before {
				background: rgba(159, 52, 199, 0.5);
			}
		}

	}
}